html,body{height: 100%;background-color:#eee;}
header, main, footer {
    padding-left: 300px;
  }

@media only screen and (max-width : 992px) {
    header, main, footer {
      padding-left: 200px;
    }
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}
button,
select {
  text-transform: none;
}
main{
  height: 100%;
}

/* Input Field
   ========================================================================== */
input{
  position: relative;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  outline: none;
  height: 3rem;
  line-height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin: 0 0 20px 0;
  padding: 0;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 300ms;
}
input:focus {
  border-bottom: 1px solid #26a69a;
  box-shadow: 0 1px 0 0 #26a69a;
}
input::placeholder{
  font-weight: 600;
  font-size: 0.8rem;
  opacity: 0.8;
}
input.form-control{
  padding: 0;
  border: none !important;
  border-bottom: 1px solid #9e9e9e !important;
  margin-bottom: 20px !important;
  border-radius: 0px !important;
  font-size: 1rem;
  background-color: transparent;
  outline: none;
}
input.form-control:focus {
  background-color: none !important;
  border-bottom: 1px solid #26a69a;
  box-shadow: 0 1px 0 0 #26a69a;
}
input.form-control::placeholder{
  font-weight: 600;
  font-size: 0.8rem;
  opacity: 0.8;
}
input.document-field{
  height: 30px !important;
}
.form-label{
  margin: 0;
  font-size: 0.8rem;
}
.paymentButton {
  background: #369;
  color: #fff;
  padding: 10px;
  font-weight: bold;
  display: flex;
  width: 220px;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  cursor: pointer;
}
.paymentButton i{
  margin-right: 3px;
}


/* Select Field
   ========================================================================== */
select {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  padding: 5px;
  margin: 0 0 20px 0;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0px;
  height: 3rem;
  outline: none;
  transition: all 0.3s;
}
select:focus{
  border-bottom: 1px solid #26a69a;
  box-shadow: 0 1px 0 0 #26a69a;
}
select option {
  padding: 6px 3px;
}
/* SELECT para DatePicker */
.ui-datepicker-calendar td{
  padding: 0 !important;
}
select.ui-datepicker-year, select.ui-datepicker-month{
  background-color: transparent;
  border-bottom: none;

  &:valid {
    border-bottom: none;
    box-shadow: none;
  }
}

.select-normal{
  background-color: transparent;

  &:valid {
    box-shadow: none;
  }
}

/* Textarea Field
   ========================================================================== */
textarea {
  margin: 0 0 20px 0;
}
.material-textarea {
  width: 100%;
  height: 3rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin: 0 0 15px 0;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: all 0.3s;
}
.material-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #26a69a;
  box-shadow: 0 1px 0 0 #26a69a;
}

/* CSS en manejo de llenado en formularios
========================================================================== */
input:not(:placeholder-shown),
textarea:not(:placeholder-shown),
select:valid {
  border-bottom: 1px solid #4CAF50;
  box-shadow: 0 1px 0 0 #4CAF50;
}
.required-field{
  border-bottom: 1px solid #F44336;
  box-shadow: 0 1px 0 0 #F44336;
}
span.invalid{
  color:#F44336;
  font-size: 14px;
  font-style: italic;
}
/* Tables
   ========================================================================== */
table {
  font-size: 15px !important;
}
table td {
  padding: 15px 5px !important;
  vertical-align: middle;
}

label.error {
  font-size: 16px;
  color: #f44336;
  font-style: italic;
  height: 14px;
}

/* btn BOOTSTRAP ajustados
   ========================================================================== */
.btn{
  font-size: 1rem;
  border-radius: 2px;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  text-align: center;
  letter-spacing: 0.5px;
  transition: .2s ease-out;
  cursor: pointer;
}
.btn-lg{
  padding: 0 28px;
  height: 54px;
  line-height: 54px;
}
.btn-teal{
  color: #fff;
  background-color: #26a69a;
}
.btn:hover{
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn-teal:hover{
  background-color: #2bbbad;
  color: #fff;
}
/* ========================================================================== */
.row{
  margin: 0;
}
.modal-backdrop {
  z-index: 1004;
}
 a.list-group-item  {
  padding: 20px;
  transition: .25s;
  color: #26a69a;
}
.input-field label{color:#000000 !important;}

/* -- --- --- -- -*/
/* nav */
nav{background-color:#FFF;}
nav ul a{color:#000;}
.sidebar_menu_mx li.active{background-color: #1E88E5 !important}
.sidebar_menu_mx li.active > a, nav ul li.active a i.material-icons{color: #FFF !important;}
/*--------*/

/* sidebar_menu_mx and sidebar*/
#slide-out li:first-child{
  text-indent: 0px;
}
.sidebar_menu_mx li{
  padding: 0px;
}
.sidebar_menu_mx .collapsible-header{
  margin:0;
}
.sidebar_menu_mx .collapsible-body{
  color:#000;
  padding: 0px;
}
span.badge.new:after {
    content: "";
}
/* --------------------*/

/* loader */
#progress-header{
  margin:0;
  display:none;
  background-color: #FFE0B2;
  position: fixed;
}
#progress-header > .indeterminate{
  background-color: #EF6C00;
}

#loader-container{
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  background-color: white;
  opacity: 0.9;
  padding-top: 25%;
}

/* General*/
.card-header{
  width: 100%;
  height: 10px;
}
.container-form{
  padding: 25px 25px 35px;
}
#container-preloader{
 display: none;
}
#activity-container{
  min-height: 450px;
  padding: 15px;
}
.alert-container{
  font-size: 21px;
  padding: 20px;
  margin: 20px 0px;
  border:1px solid #ccc;
  border-radius: 2px;
}

.alert-container:hover {
  border-color: #4093d0;
  cursor: pointer;
}

.policies-number-circle{
  width: 60px;
  height: 60px;
  padding-top: 10px;
  display: inline-block;
  border:3px solid #FFF;
  border-radius: 100%;
  margin-top: 10px;
}

#policy-notificacion{padding: 15px;}
.icons-container{
  margin: 15px 0px 25px 0px;
  padding: 15px 0px;
  border-radius:5px;
}
@media(max-width:580px){
  .alert-container{font-size: 17px;}
  .step-container {
    padding: 5px !important;
  }
  main {
    padding-left: 0 !important;
  }
}


/* Color Border */  
.border-color-teal{border-color:#009688;}
.border-color-blue{border-color:#42A5F5;}
.border-color-orange{border-color:#FF9800;}

#result-information{
    border: 1px solid #ccc;
}

/* List */
#list_name li{
    min-height: 50px;
    border-top: 1px solid #ccc;
    cursor:pointer;
}
#search-content{
    margin-top:30px;
    padding-top: 30px;
    padding-bottom: 30px; 
}

/* Modal */
#modal-search{
    z-index: 1050 !important;
}
/* charts */
.chart{
    width: 100% !important;
    height: 500px;
}

/*  Calendar */
.fc-toolbar{
    background: url(../../../public/images/general/wallpapers-calendar.png) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; 
    height: 200px;
    padding-top: 10%;
    margin-bottom: 0px !important;
}
.fc-toolbar h2{
    display: inline !important;
    font-size: 2.50em !important;
}
.fc-ltr .fc-basic-view .fc-day-number {
    text-align: left !important;
}
.fc-icon{
    font-size: 2em !important;
}
.fc-state-active{
    background-color: #FFF !important;
     box-shadow: none !important;
}

/* circle */
.circle-list{
    width: 10px;
    height: 10px;
    border-radius:100%;
    margin-right: 10px;
    display: inline-block;
}

/* Activity list */
.activity-list li{
    padding: 5px 0px;
}
.activity-list li small{
    margin-left: 20px;
}

/* Box Information*/
.box-information{
    padding: 20px 30px;
}

/* Credit Card Response */
.credit-card-response{
    background-color: #FFF59D;
    text-align: center;
    padding: 20px
}

/*Matierial Icons */
nav i, nav [class^="mdi-"], nav [class*="mdi-"], nav i.material-icons {
  display:inline !important;
}

/* Offline */
#connection-message{
  position:fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  vertical-align: middle;
  padding-top: 15px;
  z-index: 99999;
  transition: width 2s, height 2s;
  color: white;
  font-weight: bold;
}
.offline{
  background-color: #EF5350;  
}
.online{
  background-color: #689F38;
}
.collection a.collection-item{padding: 20px 20px;}
div.line {
    height: 2px;
    width: 100%;
    margin: 15px 0px;
}
.rounded-return-btn{
  width: 45px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
/* Auto Complete */
.autocomplete-suggestions {background: #FFF; overflow: auto;box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2); }
.autocomplete-suggestion { padding: 15px 10px; white-space: nowrap; overflow: hidden;cursor: pointer; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 15px 10px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }

/* Materialize (Se tomó de ahí) */
.clearfix {
  clear: both;
}
.orange {
  background-color: #ff9800 !important;
}
.orange-text.text-lighten-1 {
  color: #ffa726 !important;
}
.white-text {
  color: #FFFFFF !important;
}
.teal-text {
  color: #009688 !important;
}
.grey-text {
  color: #9e9e9e !important;
}
.blue-text {
  color: #2196F3 !important;
}
.text-blue-darken {
  color: #2196f3;
}
.orange-text {
  color: #ff9800 !important;
}
.yellow-text {
  color: #ffeb3b !important;
}
.yellow-text.text-darken-3 {
  color: #f9a825 !important;
}
.blue-text.text-lighten-1 {
  color: #42A5F5 !important;
}
.white {
  background-color: #FFFFFF !important;
}
.teal {
  background-color: teal !important;
}
.blue-text.text-darken-1 {
  color: #1E88E5 !important;
}
.blue.darken-1 {
  background-color: #1E88E5 !important;
}
.divider {
  height: 1px;
  overflow: hidden;
  background-color: #e0e0e0;
}
.z-depth-1{
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.add-circle{
  width: 64px;
  height: 64px;
}

.card-panel {
  transition: box-shadow .25s;
  padding: 20px;
  margin: 0.5rem 0 1rem 0;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

i.left {
  float: left;
  margin-right: 10px;
}

i.right {
  float: right;
  margin-left: 10px;
}

.fa-solid.fa-xmark{
  cursor: pointer
}